import styled, { createGlobalStyle } from 'styled-components'
import { createTheme } from '@material-ui/core/styles'
import HelveticaforTarget from '../assets/fonts/HelveticaforTarget-Rm.otf'

export const theme = createTheme({
  palette: {
    primary: {
      main: '#cc0000',
    },
  },
})

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: HelveticaNeue for Target;
    src: url(${HelveticaforTarget}) format('opentype');
    font-weight: 400;
    font-style: normal;
  }
  html {
    font-size: 16px;
  }
`

export const PageContainer = styled.div`
  height: 100%;
  display: flex;
  margin: 48px;
  flex-grow: 1;
`
