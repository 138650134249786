import Home from './pages/Home'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { PrivacyPolicy } from './pages/PrivacyPolicy'
import { FAQ } from './pages/FAQ'
import Header from './components/Header'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { GlobalStyle, theme } from './components/StyledComponents'

export const App = () => {
  return (
    <MuiThemeProvider theme={theme}>
      <GlobalStyle />
      <Router>
        <Header />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route exact path="/faq" element={<FAQ />} />
        </Routes>
      </Router>
    </MuiThemeProvider>
  )
}
export default App
