import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

export default function Header() {
  return (
    <>
      <AppBar position="fixed">
        <Toolbar>
          <Title variant="h6">
            <NavLinkButton to="/">Target Carrier</NavLinkButton>
          </Title>
          <Button>
            <NavLinkButton to="/privacy-policy">Privacy Policy</NavLinkButton>
          </Button>
          <Button>
            <NavLinkButton to="/faq">FAQ</NavLinkButton>
          </Button>
          <LogoutButton variant="outlined" onClick={() => logout()}>
            Logout
          </LogoutButton>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </>
  )

  function logout() {
    let logoutHost
    let logoutPath = '/login/responses/logoff.html'
    if (
      window.location.hostname.includes('dev') ||
      window.location.hostname.includes('local')
    ) {
      logoutHost = 'https://oauth.iam.perf.partnersonline.com'
    } else {
      logoutHost = 'https://oauth.iam.partnersonline.com'
    }

    window.location = `${logoutHost}${logoutPath}`
  }
}

const Title = styled(Typography)`
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`

const NavLinkButton = styled(NavLink)`
  text-decoration: none;
  color: white;
`

const LogoutButton = styled(Button)`
  color: white;
  border: 1px solid;
`
