import { Grid, Typography } from '@material-ui/core'
import { PageContainer } from '../components/StyledComponents'
import styled from 'styled-components'

export const PrivacyPolicy = () => {
  return (
    <PageContainer>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography id="privacy_policy" variant="h4" mb={2}>
            Carrier App Privacy Policy
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" mt={8} gutterBottom={true}>
            What Information is Collected?
          </Typography>
          <Typography>
            This Application (“App”) is intended for use by Drivers who
            transport packages for Target Corporation. Types of information it
            collects include:
          </Typography>
          <Typography component={'div'}>
            <ul>
              <li>
                Delivery events recorded by the Driver during the lifecycle of
                the transport (e.g. Driver picks up packages at store, Driver
                drops off packages at mixing center, etc.) along with date and
                time the Driver recorded the event
              </li>
              <li>
                Precise driver geolocation while using the app and driving for
                Target, from when the driver presses the "Start route" button to
                delivery/departure at the final stop on the route
              </li>
              <li>
                Driver’s GPS as they enter geofences around stores and mixing
                centers
              </li>
              <li>Driver login information</li>
            </ul>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom={true}>
            How is the Information Used?
          </Typography>
          <Typography>We use the information we collect to:</Typography>
          <Typography component={'div'}>
            <ul>
              <li>
                Communicate delivery details to operational personnel within
                Target
              </li>
              <li>Track location of Drivers</li>
              <li>Track lifecycle of packages</li>
              <li>Perform analytics</li>
            </ul>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom={true}>
            How is the Information Shared?
          </Typography>
          <Typography>
            The information we collect may be shared as follows:
          </Typography>
          <Typography component={'div'}>
            <ul>
              <li>
                Within the Target Family, including Target Corporation and all
                Target subsidiaries and affiliates
              </li>
              <li>
                Service Providers: Companies that provide support services to us
                that need information about you in order to perform their
                functions; these companies are not authorized to use the
                information for any other purpose and must safeguard it
              </li>
              <li>
                Legal requirements: includes when we believe disclosure is
                appropriate to comply with the law; to enforce or apply
                applicable terms and conditions and other agreements; or to
                protect the rights, property, or safety of our company, our
                guests, our team members, or others
              </li>
            </ul>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom={true}>
            How is Your Personal Information Protected?
          </Typography>
          <Typography>
            We maintain administrative, technical and physical safeguards to
            protect information we collect.
          </Typography>
          <br />
          <Typography>
            California Residents: For state-specific information, please see the
            California Business Privacy Notice at{' '}
            <a
              href="https://corporate.target.com/about/contact-help#privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://corporate.target.com/about/contact-help#privacy
            </a>
            .
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom={true}>
            Who to Contact
          </Typography>
          <Typography gutterBottom={true}>
            The Target Carrier app is owned and operated by Target Corporation.
          </Typography>
          <Typography gutterBottom={true}>Mail:</Typography>
          <ContactTextContent variant="body2">Target Stores</ContactTextContent>
          <ContactTextContent variant="body2">
            Target Executive Offices, PO Box 9350
          </ContactTextContent>
          <ContactTextContent variant="body2">
            Minneapolis, MN 55440
          </ContactTextContent>
          <br />
          <Typography gutterBottom={true}>Phone:</Typography>
          <ContactTextContent variant="body2">
            1-800-440-0680
          </ContactTextContent>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Privacy Policy Updates</Typography>
          <Typography>
            Please check this privacy policy periodically for changes. We will
            also notify you of significant updates and will post the date it was
            last updated at the bottom of the privacy policy.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="overline">Last Updated: 12/27/22</Typography>
        </Grid>
      </Grid>
    </PageContainer>
  )
}

const ContactTextContent = styled(Typography)`
  text-indent: 16px;
`
