import { Helmet } from 'react-helmet'
import IosBadgeLogo from '../assets/ios_badge.svg'
import logo from '../assets/carrier_app.png'
import qrCode from '../assets/qrcode.54586213.png'
import { Grid, Typography } from '@material-ui/core'
import styled from 'styled-components'
import { PageContainer } from '../components/StyledComponents'

export const Home = () => {
  return (
    <PageContainer>
      <Helmet defaultTitle="tdmapol" titleTemplate="%s - tdmapol" />
      <Grid container directon="column">
        <HomePageContent item xs={12}>
          <AppLogo src={logo} alt={'Application Logo'} />
        </HomePageContent>
        <HomePageContent item xs={12}>
          <Typography variant="h5" align="center">
            The Target Carrier app is for use by drivers performing deliveries
            for Target.
          </Typography>
        </HomePageContent>
        <Divider item xs={12}>
          <hr />
        </Divider>
        <Grid container item xs alignItems="center">
          <HomePageContent item xs={12} sm={6}>
            <TextContent variant="h6" align="center">
              To download the mobile app, scan the QR code or click on the links
              for iOS and Android.
            </TextContent>
          </HomePageContent>
          <HomePageContent item xs={12} sm={3}>
            <img src={qrCode} alt={'Application Badges'} />
          </HomePageContent>
          <HomePageContent item xs={12} sm={3}>
            <div>
              <div>
                <a href="https://play.google.com/store/apps/details?id=com.target.lastmile.driverapp&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                  <img
                    alt="Get it on Google Play"
                    src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                    height={83}
                    width={203}
                  />
                </a>
              </div>
              <IosBadge>
                <a href="https://itunes.apple.com/us/app/target-carrier/id1521599703?mt=8">
                  <IosBadgeLogo alt={'Get it on App Store'} />
                </a>
              </IosBadge>
            </div>
          </HomePageContent>
        </Grid>
      </Grid>
    </PageContainer>
  )
}

const HomePageContent = styled(Grid)`
  display: flex;
  justify-content: center;
`

const Divider = styled(Grid)`
  padding-top: 32px;
  padding-bottom: 32px;
`

const TextContent = styled(Typography)`
  padding: 16px;
`

const AppLogo = styled.img`
  height: 256px;
  width: 256px;
`

const IosBadge = styled.div`
  height: 60px;
  width: 207px;
`

export default Home
