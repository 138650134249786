import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import { PageContainer } from '../components/StyledComponents'

export const FAQ = () => {
  return (
    <PageContainer>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h4">Frequently Asked Questions</Typography>
        </Grid>
        <Grid item xs>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Q. Why can't I see my loads?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2" component={'div'}>
                Common troubleshooting steps include:
                <ul>
                  <li>Try changing the date filter</li>
                  <li>Ensure your dispatcher has assigned you the load</li>
                  <li>Try switching between wifi or cellular data</li>
                  <li>Close and reopen the app</li>
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                Q. I accidentally arrived at my stop too early, what should I
                do?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2">
                Open the event details and change the time with the UPDATE
                button.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                Q. How do I turn off automatic arrival & departure events?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2">
                Go to Settings and switch off Auto Stop Events.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                Q. What should I do if I have a question about my route or
                another non-app related business question?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2">
                Please reach out to
                Target.SortationCenterTransportation@target.com
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </PageContainer>
  )
}
